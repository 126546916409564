import * as React from 'react'

import { SimpleDivider } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { BaseWidgetProps } from '../types'

import { Separator } from '@thg-commerce/gravity-elements'

export type SimpleDividerProps = BaseWidgetProps &
  Omit<SimpleDivider, 'query'> & {
    marginTop: number
    marginBottom: number
    maxWidth: number
  }

export const VisualDividerWidgetRenderer = (props: SimpleDividerProps) => {
  return (
    <Separator
      separatorMargin={{
        top: props.marginTop,
        right: 0,
        bottom: props.marginBottom,
        left: 0,
      }}
      maxWidth={props.maxWidth}
    />
  )
}
